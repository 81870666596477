import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
import softwareconsult1 from "@/images/softwareconsult1.jpg";
import softwareconsult3 from "@/images/softwareconsult3.jpg";
const ServiceDetailsContentFive = () => {
  return (
    <div className="serviceArea">
      <img src={softwareconsult1} alt="" />
      <h2>Software Consultations And Advice From Trusted Professionals </h2>
      <p>
        The world is rapidly transforming and changing in this digital era. 
        With new and existing businesses emerging and growing at a fast pace, 
        and rising competition at hand, businesses should be able to adapt to 
        the changes and meet the widely-ranging requirements of their customers. 
        This is where technology plays its part in bringing about the required 
        transformation for these businesses to achieve growth. With its 
        professionals having about over a decade’s experience in the industry, 
        Kloudxel provides you with IT and software consulting services that create 
        and add value for you. 
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={softwareconsult3} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Consultancy  Services</h3>
          <p>
            We offer the following services:
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Identifying Your Business Objectives
            </li>
            <li>
              <i className="fa fa-check-square"></i>Consumer And Market Research
            </li>
            <li>
              <i className="fa fa-check-square"></i>Developing Your Digital Strategy
            </li>
          </ul>
        </div>
      </div>
      <p>
        Kloudxel offers you consultancy and expertise in various tech stacks 
        and platforms, advising you on the best approach that would be feasible 
        for you to meet your technology challenges. This  process is based on 
        interviews, brainstorming, facilitated workshops, and surveys to help us 
        find out what our clients want. This thorough understanding helps our 
        professionals determine and fulfill your goals and objectives.
      </p>
    </div>
  );
};

export default ServiceDetailsContentFive;
